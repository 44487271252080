
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from '../../helpers/Firebase';
import {
    LOGIN_USER,
    LOGIN_USER_FACEBOOK,
    REGISTER_USER,
    REGISTER_USER_FACEBOOK,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    RETRIEVE_CUSTOMER_BY_ID
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    retrieveCustomerByIdSuccess,
    retrieveCustomerByIdError,
} from './actions';

import axios from "axios";
import {
    login as loginPresence,
    loginFacebook as loginFacebookPresence,
    register as registerPresence,
    registerFacebook as registerFacebookPresence,
    retrieveCustomerById,
} from '../../helpers/PresenceAPI';
import {presenceApiUrl} from '../../constants/defaultValues'

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = (email, password) =>
    loginPresence(axios)(presenceApiUrl)(email, password)
        .catch(error => error)

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (loginUser.token) {
            localStorage.setItem('user_id', loginUser.user._id);
            localStorage.setItem('user_token', loginUser.token);
            localStorage.setItem('user_name', loginUser.user.name);
            yield put(loginUserSuccess(loginUser));
            history.push('/app');
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}

export function* watchLoginUserFacebook() {
    yield takeEvery(LOGIN_USER_FACEBOOK, loginWithFacebook);
}

const loginWithFacebookAsync = (id, name, email, accessTokenFB) =>{
    return loginFacebookPresence(axios)(presenceApiUrl)(id, name, email, accessTokenFB)
        .catch(error => error)

}

function* loginWithFacebook({ payload }) {
    const { id, name, email, accessTokenFB } = payload.user;
    const { history } = payload;

    try {
        const loginUser = yield call(loginWithFacebookAsync, id, name, email, accessTokenFB);
        if (loginUser.token) {
            localStorage.setItem('user_id', loginUser.user._id);
            localStorage.setItem('user_token', loginUser.token);
            localStorage.setItem('user_name', loginUser.user.name);
            yield put(loginUserSuccess(loginUser));
            history.push('/app');
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));

    }
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (email, password, name) =>
    registerPresence(axios)(presenceApiUrl)(email, password, name)
        .catch(error => error)

function* registerWithEmailPassword({ payload }) {
    const { email, password, name } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password, name);
        if (registerUser.token) {
            localStorage.setItem('user_id', registerUser.user._id);
            localStorage.setItem('user_token', registerUser.token);
            localStorage.setItem('user_name', registerUser.user.name);
            yield put(registerUserSuccess(registerUser));
            history.push('/app')
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}


export function* watchRegisterUserFacebook() {
    yield takeEvery(REGISTER_USER_FACEBOOK, registerFacebook);
}

const registerWithFacebookAsync = async (userFacebookId, name, email, accessTokenFacebook) =>
    registerFacebookPresence(axios)(presenceApiUrl)(userFacebookId, name, email, accessTokenFacebook)
        .catch(error => error)


function* registerFacebook({ payload }) {
    const { id, name, email, accessTokenFacebook } = payload.user;
    const { history } = payload
    try {
        const registerUser = yield call(registerWithFacebookAsync, id, name, email, accessTokenFacebook);
        if (registerUser.token) {
            localStorage.setItem('user_id', registerUser.user._id);
            localStorage.setItem('user_token', registerUser.token);
            localStorage.setItem('user_name', registerUser.user.name);
            yield put(registerUserSuccess(registerUser));
            history.push('/app')
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}


export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({ payload }) {
    const { history } = payload
    try {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_token');
        localStorage.removeItem('user_name');
        yield call(logoutAsync, history);
    } catch (error) {
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await auth.confirmPasswordReset(resetPasswordCode, newPassword)
        .then(user => user)
        .catch(error => error);
}

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(resetPasswordAsync, resetPasswordCode, newPassword);
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess("success"));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));

    }
}

export function* watchRetriveCustomerById() {
    yield takeEvery(RETRIEVE_CUSTOMER_BY_ID, retriveCustomerByIdFn);
}

const retriveCustomerByIdFnAsync = (customerId, customerToken) =>
    retrieveCustomerById(axios)(presenceApiUrl)(customerId, customerToken)

function* retriveCustomerByIdFn({ payload }) {
    const { customerId, customerToken } = payload

    try {
        const customerData = yield call(retriveCustomerByIdFnAsync, customerId, customerToken);
        if (customerData._id) {
            yield put(retrieveCustomerByIdSuccess(customerData))
        } else {
            yield put(retrieveCustomerByIdError(customerData.message));
        }
    } catch (error) {
        yield put(retrieveCustomerByIdError(error));
    }
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLoginUserFacebook),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchRegisterUserFacebook),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchRetriveCustomerById),
    ]);
}
