import {
    VALIDATIONS_CLEAN_PROPS,
    VALIDATIONS_EMAILS_ACTIVATE,
    VALIDATIONS_EMAILS_ACTIVATE_SUCCESS,
    VALIDATIONS_EMAILS_ACTIVATE_ERROR,
    VALIDATIONS_EMAILS_DEACTIVATE,
    VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS,
    VALIDATIONS_EMAILS_DEACTIVATE_ERROR,
    VALIDATIONS_FACEBOOK_ACTIVATE,
    VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS,
    VALIDATIONS_FACEBOOK_ACTIVATE_ERROR,
    VALIDATIONS_FACEBOOK_DEACTIVATE,
    VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS,
    VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR,
    VALIDATIONS_PAYMENT_ACTIVATE,
    VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS,
    VALIDATIONS_PAYMENT_ACTIVATE_ERROR,
    VALIDATIONS_SPOTIFY_ACTIVATE,
    VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS,
    VALIDATIONS_SPOTIFY_ACTIVATE_ERROR,
    VALIDATIONS_SPOTIFY_DEACTIVATE,
    VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS,
    VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR,
} from '../actions';

const INIT_STATE = {
    validationEmailsData: {},
    validationFacebookData: {},
    validationSpotifyData: {},
    deactivateOp: false,
    loading: false,
    error: '',
    message: '',
    paymentMessage: '',
    paymentError: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case VALIDATIONS_CLEAN_PROPS:
            return { ...state, loading: false, error: null, validationEmailsData: {}, validationFacebookData: {}, validationSpotifyData: {}, deactivateOp: false };
        case VALIDATIONS_EMAILS_ACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_EMAILS_ACTIVATE_SUCCESS:
            return { ...state, loading: false, validationEmailsData: action.payload.data, error: ''};
        case VALIDATIONS_EMAILS_ACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case VALIDATIONS_EMAILS_DEACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS:
            return { ...state, loading: false, deactivateOp: true, validationEmailsData: action.payload.data, error: ''};
        case VALIDATIONS_EMAILS_DEACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case VALIDATIONS_FACEBOOK_ACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS:
            return { ...state, loading: false, validationFacebookData: action.payload.data, error: ''};
        case VALIDATIONS_FACEBOOK_ACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case VALIDATIONS_FACEBOOK_DEACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS:
            return { ...state, loading: false, deactivateOp: true, validationFacebookData: action.payload.data, error: ''};
        case VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case VALIDATIONS_PAYMENT_ACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS:
            return { ...state, validationsPaid: true, paymentMessage: action.payload.paymentMessage, paymentError: '', loading: false};
        case VALIDATIONS_PAYMENT_ACTIVATE_ERROR:
            return { ...state, loading: false, paymentError: action.payload.paymentError};
        case VALIDATIONS_SPOTIFY_ACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS:
            return { ...state, loading: false, validationSpotifyData: action.payload.data, error: ''};
        case VALIDATIONS_SPOTIFY_ACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case VALIDATIONS_SPOTIFY_DEACTIVATE:
            return { ...state, loading: true};
        case VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS:
            return { ...state, loading: false, deactivateOp: true, validationSpotifyData: action.payload.data, error: ''};
        case VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        default: return { ...state };
    }
}
