
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    VALIDATIONS_EMAILS_ACTIVATE,
    VALIDATIONS_EMAILS_DEACTIVATE,
    VALIDATIONS_FACEBOOK_ACTIVATE,
    VALIDATIONS_FACEBOOK_DEACTIVATE,
    VALIDATIONS_PAYMENT_ACTIVATE,
    VALIDATIONS_SPOTIFY_ACTIVATE,
    VALIDATIONS_SPOTIFY_DEACTIVATE
} from '../actions';
import {
    activateEmailsValidationSuccess,
    activateEmailsValidationError,
    deactivateEmailsValidationSuccess,
    deactivateEmailsValidationError,
    activateFacebookValidationSuccess,
    activateFacebookValidationError,
    deactivateFacebookValidationSuccess,
    deactivateFacebookValidationError,
    activatePaymentValidationsSuccess,
    activatePaymentValidationsError,
    activateSpotifyValidationSuccess,
    activateSpotifyValidationError,
    deactivateSpotifyValidationSuccess,
    deactivateSpotifyValidationError,
} from './actions';
import axios from "axios";
import { presenceApiUrl } from '../../constants/defaultValues'
import {
    updateEmailsValidationByCustomer,
    activateFacebookValidationByCustomer,
    deactivateFacebookValidationByCustomer,
    activatePaymentValidationsByCustomer,
    activateSpotifyValidationByCustomer,
    deactivateSpotifyValidationByCustomer,
} from '../../helpers/PresenceAPI';

export function* watchActivateEmailsValidation() {
    yield takeEvery(VALIDATIONS_EMAILS_ACTIVATE, activateEmailsValidationFn);
}

const activateEmailsValidationFnAsync = (customerId, customerToken, data) =>
    updateEmailsValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* activateEmailsValidationFn({ payload }) {
    const { customerId, customerToken, values } = payload
    const data = { active: true, details: values }

    try {
        const customerData = yield call(activateEmailsValidationFnAsync, customerId, customerToken, data);
        if (customerData.validations.emails) {
            yield put(activateEmailsValidationSuccess(customerData.validations.emails))
        } else {
            yield put(activateEmailsValidationError(customerData.message));
        }
    } catch (error) {
        yield put(activateEmailsValidationError(error.message));
    }
}

export function* watchDeactivateEmailsValidation() {
    yield takeEvery(VALIDATIONS_EMAILS_DEACTIVATE, deactivateEmailsValidationFn);
}

const deactivateEmailsValidationFnAsync = (customerId, customerToken, data) =>
    updateEmailsValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* deactivateEmailsValidationFn({ payload }) {
    const { customerId, customerToken } = payload
    const data = { active: false, details: {} }

    try {
        const customerData = yield call(deactivateEmailsValidationFnAsync, customerId, customerToken, data);
        if (customerData.validations.emails) {
            yield put(deactivateEmailsValidationSuccess(customerData.validations.emails))
        } else {
            yield put(activateEmailsValidationError(customerData.message));
        }
    } catch (error) {
        yield put(deactivateEmailsValidationError(error.message));
    }
}

export function* watchActivateFacebookValidation() {
    yield takeEvery(VALIDATIONS_FACEBOOK_ACTIVATE, activateFacebookValidationFn);
}

const activateFacebookValidationFnAsync = (customerId, customerToken, data) =>
    activateFacebookValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* activateFacebookValidationFn({ payload }) {
    const { customerId, customerToken, values } = payload

    try {
        const customerData = yield call(activateFacebookValidationFnAsync, customerId, customerToken, values)
        if (customerData.validations.facebook) {
            yield put(activateFacebookValidationSuccess(customerData.validations.facebook))
        } else {
            yield put(activateFacebookValidationError(customerData.message));
        }
    } catch (error) {
        yield put(activateFacebookValidationError(error.message));
    }
}

export function* watchDeactivateFacebookValidation() {
    yield takeEvery(VALIDATIONS_FACEBOOK_DEACTIVATE, deactivateFacebookValidationFn);
}

const deactivateFacebookValidationFnAsync = (customerId, customerToken, data) =>
    deactivateFacebookValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* deactivateFacebookValidationFn({ payload }) {
    const { customerId, customerToken } = payload
    const data = {}

    try {
        const customerData = yield call(deactivateFacebookValidationFnAsync, customerId, customerToken, data);
        if (customerData.validations.facebook) {
            yield put(deactivateFacebookValidationSuccess(customerData.validations.facebook))
        } else {
            yield put(deactivateFacebookValidationError(customerData.message));
        }
    } catch (error) {
        yield put(deactivateFacebookValidationError(error.message));
    }
}

export function* watchActivatePaymentValidation() {
    yield takeEvery(VALIDATIONS_PAYMENT_ACTIVATE, activatePaymentValidationsFn);
}

const activatePaymentValidationsFnAsync = (customerId, customerToken, type, data) =>
    activatePaymentValidationsByCustomer(axios)(presenceApiUrl)(customerId, customerToken, type, data)

function* activatePaymentValidationsFn({ payload }) {
    const { customerId, customerToken, type, data } = payload;

    try {
        const response = yield call(activatePaymentValidationsFnAsync, customerId, customerToken, type, data);
        if (response.paid) {
            yield put(activatePaymentValidationsSuccess(response.message));
        } else {
            yield put(activatePaymentValidationsError(response.message));
        }
    } catch (error) {
        yield put(activatePaymentValidationsError(error.message));
    }
}

export function* watchActivateSpotifyValidation() {
    yield takeEvery(VALIDATIONS_SPOTIFY_ACTIVATE, activateSpotifyValidationFn);
}

const activateSpotifyValidationFnAsync = (customerId, customerToken, data) =>
    activateSpotifyValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* activateSpotifyValidationFn({ payload }) {
    const { customerId, customerToken, values } = payload

    try {
        const customerData = yield call(activateSpotifyValidationFnAsync, customerId, customerToken, values)
        console.info('customerData: ', customerData)
        if (customerData.validations.spotify) {
            yield put(activateSpotifyValidationSuccess(customerData.validations.spotify))
        } else {
            yield put(activateSpotifyValidationError(customerData.message));
        }
    } catch (error) {
        yield put(activateSpotifyValidationError(error.message));
    }
}

export function* watchDeactivateSpotifyValidation() {
    yield takeEvery(VALIDATIONS_SPOTIFY_DEACTIVATE, deactivateSpotifyValidationFn);
}

const deactivateSpotifyValidationFnAsync = (customerId, customerToken, data) =>
    deactivateSpotifyValidationByCustomer(axios)(presenceApiUrl)(customerId, customerToken, data)

function* deactivateSpotifyValidationFn({ payload }) {
    const { customerId, customerToken } = payload
    const data = {}

    try {
        const customerData = yield call(deactivateSpotifyValidationFnAsync, customerId, customerToken, data);
        if (customerData.validations.spotify) {
            yield put(deactivateSpotifyValidationSuccess(customerData.validations.spotify))
        } else {
            yield put(deactivateSpotifyValidationError(customerData.message));
        }
    } catch (error) {
        yield put(deactivateSpotifyValidationError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchActivateEmailsValidation),
        fork(watchDeactivateEmailsValidation),
        fork(watchActivateFacebookValidation),
        fork(watchDeactivateFacebookValidation),
        fork(watchActivatePaymentValidation),
        fork(watchActivateSpotifyValidation),
        fork(watchDeactivateSpotifyValidation),
    ]);
}
