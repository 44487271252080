import {
  RECIPIENTS_CLEAN_PROPS,
  RECIPIENTS_GET_DATA,
  RECIPIENTS_GET_DATA_SUCCESS,
  RECIPIENTS_GET_DATA_ERROR,
  RECIPIENTS_GET_ONE,
  RECIPIENTS_GET_ONE_SUCCESS,
  RECIPIENTS_GET_ONE_ERROR,
  RECIPIENTS_CREATE_ONE,
  RECIPIENTS_CREATE_ONE_SUCCESS,
  RECIPIENTS_CREATE_ONE_ERROR,
  RECIPIENTS_EDIT_ONE,
  RECIPIENTS_EDIT_ONE_SUCCESS,
  RECIPIENTS_EDIT_ONE_ERROR,
  RECIPIENTS_REMOVE_ONE,
  RECIPIENTS_REMOVE_ONE_SUCCESS,
  RECIPIENTS_REMOVE_ONE_ERROR,
} from '../actions';

export const cleanRecipientsProps = () => ({
  type: RECIPIENTS_CLEAN_PROPS,
  payload: {}
})

export const getRecipient = (customerId, customerToken, recipientId) => ({
  type: RECIPIENTS_GET_ONE,
  payload: { customerId, customerToken, recipientId }
});

export const getRecipientSuccess = (recipient) => ({
  type: RECIPIENTS_GET_ONE_SUCCESS,
  payload: { recipient }
});

export const getRecipientError = (message) => ({
  type: RECIPIENTS_GET_ONE_ERROR,
  payload:  { message }
});

export const getRecipients = (customerId, customerToken) => ({
  type: RECIPIENTS_GET_DATA,
  payload: { customerId, customerToken }
});

export const getRecipientsSuccess = (recipients) => ({
  type: RECIPIENTS_GET_DATA_SUCCESS,
  payload: { recipients }
});

export const getRecipientsError = (message) => ({
  type: RECIPIENTS_GET_DATA_ERROR,
  payload:  { message }
});

export const saveRecipient = (customerId, customerToken, values) => ({
  type: RECIPIENTS_CREATE_ONE,
  payload: { customerId, customerToken, values }
})

export const saveRecipientSuccess = (message) => ({
  type: RECIPIENTS_CREATE_ONE_SUCCESS,
  payload: { message }
})

export const saveRecipientError = (error) => ({
  type: RECIPIENTS_CREATE_ONE_ERROR,
  payload: { error }
})

export const editRecipient = (customerId, customerToken, recipientId, values) => ({
  type: RECIPIENTS_EDIT_ONE,
  payload: { customerId, customerToken, recipientId, values }
})

export const editRecipientSuccess = (message) => ({
  type: RECIPIENTS_EDIT_ONE_SUCCESS,
  payload: { message }
})

export const editRecipientError = (error) => ({
  type: RECIPIENTS_EDIT_ONE_ERROR,
  payload: { error }
})

export const removeRecipient = (customerId, customerToken, recipientId) => ({
  type: RECIPIENTS_REMOVE_ONE,
  payload: { customerId, customerToken, recipientId }
})

export const removeRecipientSuccess = (message) => ({
  type: RECIPIENTS_REMOVE_ONE_SUCCESS,
  payload: { message }
})

export const removeRecipientError = (error) => ({
  type: RECIPIENTS_REMOVE_ONE_ERROR,
  payload: { error }
})
