/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "es";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;

/**
 * Presence settings
 */
export const presenceApiUrl = process.env.REACT_APP_PRESENCE_API_URL;
export const facebookAppId = process.env.REACT_APP_PRESENCE_FACEBOOK_APP_ID;
export const paypalClientId = process.env.REACT_APP_PRESENCE_PAYPAL_CLIENT_ID;
export const paypalProductId = process.env.REACT_APP_PRESENCE_PAYPAL_PRODUCT_ID;
export const paypalSubscriptionMXN = process.env.REACT_APP_PRESENCE_PAYPAL_SUBSCRIPTION_ID_MXN;
export const paypalSubscriptionUSD = process.env.REACT_APP_PRESENCE_PAYPAL_SUBSCRIPTION_ID_USD;
export const spotifyAppId = process.env.REACT_APP_PRESENCE_SPOTIFY_APP_ID;
export const spotifyRedirecUrl = process.env.REACT_APP_PRESENCE_SPOTIFY_REDIRECT_URL;
