import {
    LOGIN_USER,
    LOGIN_USER_FACEBOOK,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_FACEBOOK,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    RETRIEVE_CUSTOMER_BY_ID,
    RETRIEVE_CUSTOMER_BY_ID_SUCCESS,
    RETRIEVE_CUSTOMER_BY_ID_ERROR
} from '../actions';

const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    customerId: localStorage.getItem('user_id'),
    customerToken: localStorage.getItem('user_token'),
    customerName: localStorage.getItem('user_name'),
    customerData: {},
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    loading: false,
    error: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_FACEBOOK:
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            const login = action.payload
            return { ...state, loading: false, user: login.user._id, customerId: login.user._id, customerName: login.user.name, customerToken: login.token, error: '' };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, newPassword: action.payload, resetPasswordCode: '', error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, newPassword: '', resetPasswordCode: '', error: action.payload.message };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_FACEBOOK:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            const register = action.payload
            return { ...state, loading: false, user: register.user._id, customerId: register.user._id, customerToken: register.token, customerName: register.user.name, error: '' };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case LOGOUT_USER:
            return { ...state, user: null, customerId: null, customerToken: null, customerName: null, error: '' };
        case RETRIEVE_CUSTOMER_BY_ID:
            return { ...state, loading: true, error: '' };
        case RETRIEVE_CUSTOMER_BY_ID_SUCCESS:
            return { ...state, loading: false, customerData: action.payload.customerData, error: '' };
        case RETRIEVE_CUSTOMER_BY_ID_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        default: return { ...state };
    }
}
