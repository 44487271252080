import {
    MESSAGES_CLEAN_PROPS,
    MESSAGES_GET_ONE,
    MESSAGES_GET_ONE_SUCCESS,
    MESSAGES_GET_ONE_ERROR,
    MESSAGES_GET_DATA,
    MESSAGES_GET_DATA_SUCCESS,
    MESSAGES_GET_DATA_ERROR,
    MESSAGES_CREATE_ONE,
    MESSAGES_CREATE_ONE_SUCCESS,
    MESSAGES_CREATE_ONE_ERROR,
    MESSAGES_EDIT_ONE,
    MESSAGES_EDIT_ONE_SUCCESS,
    MESSAGES_EDIT_ONE_ERROR,
    MESSAGES_EDIT_CLEAN_PROPS,
    MESSAGES_REMOVE_ONE,
    MESSAGES_REMOVE_ONE_SUCCESS,
    MESSAGES_REMOVE_ONE_ERROR,
} from '../actions';

const INIT_STATE = {
    messagesData: [],
    messageData: {},
    loading: false,
    error: '',
    message: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MESSAGES_CLEAN_PROPS:
            return { ...state, loading: false, error: null, message: null };
        case MESSAGES_GET_ONE:
            return { ...state, loading: true};
        case MESSAGES_GET_ONE_SUCCESS:
            return { ...state, loading: false, messageData: action.payload.message, error: ''};
        case MESSAGES_GET_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case MESSAGES_GET_DATA:
            return { ...state, loading: true };
        case MESSAGES_GET_DATA_SUCCESS:
            return { ...state, loading: false, messagesData: action.payload.messages, error: '' };
        case MESSAGES_GET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        case MESSAGES_CREATE_ONE:
            return { ...state, loading: true};
        case MESSAGES_CREATE_ONE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case MESSAGES_CREATE_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case MESSAGES_EDIT_ONE:
            return { ...state, loading: true};
        case MESSAGES_EDIT_ONE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case MESSAGES_EDIT_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case MESSAGES_EDIT_CLEAN_PROPS:
            return { ...state, loading: false, messageData: {}};
        case MESSAGES_REMOVE_ONE:
            return { ...state, loading: true};
        case MESSAGES_REMOVE_ONE_SUCCESS:
            const messageId = action.payload.message.messageId
            const messagesData = state.messagesData
            state.messagesData = messagesData.filter(message => message._id !== messageId)
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case MESSAGES_REMOVE_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        default: return { ...state };
    }
}
