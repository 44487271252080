import {
  VALIDATIONS_CLEAN_PROPS,
  VALIDATIONS_EMAILS_ACTIVATE,
  VALIDATIONS_EMAILS_ACTIVATE_SUCCESS,
  VALIDATIONS_EMAILS_ACTIVATE_ERROR,
  VALIDATIONS_EMAILS_DEACTIVATE,
  VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS,
  VALIDATIONS_EMAILS_DEACTIVATE_ERROR,
  VALIDATIONS_FACEBOOK_ACTIVATE,
  VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS,
  VALIDATIONS_FACEBOOK_ACTIVATE_ERROR,
  VALIDATIONS_FACEBOOK_DEACTIVATE,
  VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS,
  VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR,
  VALIDATIONS_PAYMENT_ACTIVATE,
  VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS,
  VALIDATIONS_PAYMENT_ACTIVATE_ERROR,
  VALIDATIONS_SPOTIFY_ACTIVATE,
  VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS,
  VALIDATIONS_SPOTIFY_ACTIVATE_ERROR,
  VALIDATIONS_SPOTIFY_DEACTIVATE,
  VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS,
  VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR,
} from '../actions';

export const cleanValidationsProps = () => ({
  type: VALIDATIONS_CLEAN_PROPS,
  payload: {}
})

export const activateEmailsValidation = (customerId, customerToken, values) => ({
  type: VALIDATIONS_EMAILS_ACTIVATE,
  payload: { customerId, customerToken, values }
});
export const activateEmailsValidationSuccess = (data) => ({
  type: VALIDATIONS_EMAILS_ACTIVATE_SUCCESS,
  payload: { data }
})
export const activateEmailsValidationError = (message) => ({
  type: VALIDATIONS_EMAILS_ACTIVATE_ERROR,
  payload:  { message }
});

export const deactivateEmailsValidation = (customerId, customerToken) => ({
  type: VALIDATIONS_EMAILS_DEACTIVATE,
  payload: { customerId, customerToken }
});
export const deactivateEmailsValidationSuccess = (data) => ({
  type: VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS,
  payload: { data }
})
export const deactivateEmailsValidationError = (message) => ({
  type: VALIDATIONS_EMAILS_DEACTIVATE_ERROR,
  payload:  { message }
});

export const activateFacebookValidation = (customerId, customerToken, values) => ({
  type: VALIDATIONS_FACEBOOK_ACTIVATE,
  payload: { customerId, customerToken, values }
});
export const activateFacebookValidationSuccess = (data) => ({
  type: VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS,
  payload: { data }
})
export const activateFacebookValidationError = (error) => ({
  type: VALIDATIONS_FACEBOOK_ACTIVATE_ERROR,
  payload:  { error }
});

export const deactivateFacebookValidation = (customerId, customerToken) => ({
  type: VALIDATIONS_FACEBOOK_DEACTIVATE,
  payload: { customerId, customerToken }
});
export const deactivateFacebookValidationSuccess = (data) => ({
  type: VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS,
  payload: { data }
})
export const deactivateFacebookValidationError = (error) => ({
  type: VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR,
  payload:  { error }
});

export const activatePaymentValidations = (customerId, customerToken, type, data) => ({
  type: VALIDATIONS_PAYMENT_ACTIVATE,
  payload:  { customerId, customerToken, type, data }
});
export const activatePaymentValidationsSuccess = (paymentMessage) => ({
  type: VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS,
  payload:  { paymentMessage }
});
export const activatePaymentValidationsError = (paymentError) => ({
  type: VALIDATIONS_PAYMENT_ACTIVATE_ERROR,
  payload:  { paymentError }
});

export const activateSpotifyValidation = (customerId, customerToken, values) => ({
  type: VALIDATIONS_SPOTIFY_ACTIVATE,
  payload: { customerId, customerToken, values }
});
export const activateSpotifyValidationSuccess = (data) => ({
  type: VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS,
  payload: { data }
})
export const activateSpotifyValidationError = (error) => ({
  type: VALIDATIONS_SPOTIFY_ACTIVATE_ERROR,
  payload:  { error }
});

export const deactivateSpotifyValidation = (customerId, customerToken) => ({
  type: VALIDATIONS_SPOTIFY_DEACTIVATE,
  payload: { customerId, customerToken }
});
export const deactivateSpotifyValidationSuccess = (data) => ({
  type: VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS,
  payload: { data }
})
export const deactivateSpotifyValidationError = (error) => ({
  type: VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR,
  payload:  { error }
});
