import {
    RECIPIENTS_CLEAN_PROPS,
    RECIPIENTS_GET_ONE,
    RECIPIENTS_GET_ONE_SUCCESS,
    RECIPIENTS_GET_ONE_ERROR,
    RECIPIENTS_GET_DATA,
    RECIPIENTS_GET_DATA_SUCCESS,
    RECIPIENTS_GET_DATA_ERROR,
    RECIPIENTS_CREATE_ONE,
    RECIPIENTS_CREATE_ONE_SUCCESS,
    RECIPIENTS_CREATE_ONE_ERROR,
    RECIPIENTS_EDIT_ONE,
    RECIPIENTS_EDIT_ONE_SUCCESS,
    RECIPIENTS_EDIT_ONE_ERROR,
    RECIPIENTS_REMOVE_ONE,
    RECIPIENTS_REMOVE_ONE_SUCCESS,
    RECIPIENTS_REMOVE_ONE_ERROR,
} from '../actions';

const INIT_STATE = {
    recipientsData: [],
    recipientData: {},
    loading: false,
    error: '',
    message: '',
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RECIPIENTS_CLEAN_PROPS:
            return { ...state, loading: false, error: null, message: null };
        case RECIPIENTS_GET_ONE:
            return { ...state, loading: true};
        case RECIPIENTS_GET_ONE_SUCCESS:
            return { ...state, loading: false, recipientData: action.payload.recipient, error: ''};
        case RECIPIENTS_GET_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case RECIPIENTS_GET_DATA:
            return { ...state, loading: true };
        case RECIPIENTS_GET_DATA_SUCCESS:
            return { ...state, loading: false, recipientsData: action.payload.recipients, error: '' };
        case RECIPIENTS_GET_DATA_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        case RECIPIENTS_CREATE_ONE:
            return { ...state, loading: true};
        case RECIPIENTS_CREATE_ONE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case RECIPIENTS_CREATE_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case RECIPIENTS_EDIT_ONE:
            return { ...state, loading: true};
        case RECIPIENTS_EDIT_ONE_SUCCESS:
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case RECIPIENTS_EDIT_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        case RECIPIENTS_REMOVE_ONE:
            return { ...state, loading: true};
        case RECIPIENTS_REMOVE_ONE_SUCCESS:
            const recipientId = action.payload.message.recipientId
            const recipientsData = state.recipientsData
            state.recipientsData = recipientsData.filter(recipient => recipient._id !== recipientId)
            return { ...state, loading: false, message: action.payload.message, error: ''};
        case RECIPIENTS_REMOVE_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.error};
        default: return { ...state };
    }
}
