import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import messagesSagas from './messages/saga';
import recipientsSagas from './recipients/saga';
import validationsSagas from './validations/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    messagesSagas(),
    recipientsSagas(),
    validationsSagas()
  ]);
}
