
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    MESSAGES_GET_ONE,
    MESSAGES_GET_DATA,
    MESSAGES_CREATE_ONE,
    MESSAGES_EDIT_ONE,
    MESSAGES_REMOVE_ONE,
} from '../actions';
import {
    getMessageSuccess,
    getMessageError,
    getMessagesSuccess,
    getMessagesError,
    saveMessageSuccess,
    saveMessageError,
    editMessageSuccess,
    editMessageError,
    removeMessageSuccess,
    removeMessageError
} from './actions';
import axios from "axios";
import {presenceApiUrl} from '../../constants/defaultValues'
import {
    retrieveMessageByCustomerToken,
    retrieveMessagesByCustomerToken,
    createMessageByCustomerToken,
    editMessageByCustomerToken,
    removeMessageByCustomerToken
} from '../../helpers/PresenceAPI';

export function* watchGetMessage() {
    yield takeEvery(MESSAGES_GET_ONE, getMessageFn);
}

const getMessageFnAsync = (customerId, customerToken, messageId) =>
    retrieveMessageByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken, messageId)

function* getMessageFn({ payload }) {
    const { customerId, customerToken, messageId } = payload

    try {
        const messages = yield call(getMessageFnAsync, customerId, customerToken, messageId);
        yield put(getMessageSuccess(messages))
    } catch (error) {
        yield put(getMessageError(error.message));
    }
}

export function* watchGetMessages() {
    yield takeEvery(MESSAGES_GET_DATA, getMessagesFn);
}

const getMessagesFnAsync = (customerId, customerToken) =>
    retrieveMessagesByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken)

function* getMessagesFn({ payload }) {
    const customerId = payload.customerId
    const customerToken = payload.customerToken

    try {
        const messages = yield call(getMessagesFnAsync, customerId, customerToken);
        yield put(getMessagesSuccess(messages))
    } catch (error) {
        yield put(getMessagesError(error.message));
    }
}

export function* watchSaveMessage() {
    yield takeEvery(MESSAGES_CREATE_ONE, saveMessageFn);
}

const saveMessageFnAsync = (
    customerId,
    customerToken,
    title,
    messageText,
    recipients
) => createMessageByCustomerToken(axios)(presenceApiUrl)
    (
        customerId,
        customerToken,
        title,
        messageText,
        recipients
    )

function* saveMessageFn({ payload }) {
    const { customerId, customerToken, values } = payload
    const { title, messageText, recipients } = values

    try {
        const message = yield call(
            saveMessageFnAsync,
            customerId,
            customerToken,
            title,
            messageText,
            recipients
        );
        yield put(saveMessageSuccess({result: true, details: message}))
    } catch (error) {
        yield put(saveMessageError(error.message));
    }
}

export function* watchEditMessage() {
    yield takeEvery(MESSAGES_EDIT_ONE, editMessageFn);
}

const editMessageFnAsync = (
    customerId,
    customerToken,
    messageId,
    title,
    messageText,
    recipients
) => editMessageByCustomerToken(axios)(presenceApiUrl)
    (
        customerId,
        customerToken,
        messageId,
        title,
        messageText,
        recipients
    )

function* editMessageFn({ payload }) {
    const { customerId, customerToken, messageId, values } = payload
    const { title, messageText, recipients } = values


    try {
        const message = yield call(
            editMessageFnAsync,
            customerId,
            customerToken,
            messageId,
            title,
            messageText,
            recipients
        );
        yield put(editMessageSuccess({result: true, details: message}))
    } catch (error) {
        yield put(editMessageError(error.message));
    }
}

export function* watchRemoveMessage() {
    yield takeEvery(MESSAGES_REMOVE_ONE, removeMessageFn);
}

const removeMessageFnAsync = (customerId, customerToken, messageId) =>
    removeMessageByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken, messageId)

function* removeMessageFn({ payload }) {
    const { customerId, customerToken, messageId } = payload

    try {
        const message = yield call(
            removeMessageFnAsync,
            customerId,
            customerToken,
            messageId
        );
        yield put(removeMessageSuccess({messageId, remove: true, name: message.name}))
    } catch (error) {
        yield put(removeMessageError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetMessage),
        fork(watchGetMessages),
        fork(watchEditMessage),
        fork(watchSaveMessage),
        fork(watchRemoveMessage),
    ]);
}
