/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_FACEBOOK = "LOGIN_USER_FACEBOOK";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_FACEBOOK = "REGISTER_USER_FACEBOOK";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RETRIEVE_CUSTOMER_BY_ID = "RETRIEVE_CUSTOMER_BY_ID";
export const RETRIEVE_CUSTOMER_BY_ID_SUCCESS = "RETRIEVE_CUSTOMER_BY_ID_SUCCESS";
export const RETRIEVE_CUSTOMER_BY_ID_ERROR = "RETRIEVE_CUSTOMER_BY_ID_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* RECIPIENTS */
export const RECIPIENTS_CLEAN_PROPS = "RECIPIENTS_CLEAN_PROPS";
export const RECIPIENTS_GET_DATA = "RECIPIENTS_GET_DATA";
export const RECIPIENTS_GET_DATA_SUCCESS = "RECIPIENTS_GET_DATA_SUCCESS";
export const RECIPIENTS_GET_DATA_ERROR = "RECIPIENTS_GET_DATA_ERROR";
export const RECIPIENTS_CREATE_ONE = "RECIPIENTS_CREATE_ONE";
export const RECIPIENTS_CREATE_ONE_SUCCESS = "RECIPIENTS_CREATE_ONE_SUCCESS";
export const RECIPIENTS_CREATE_ONE_ERROR = "RECIPIENTS_CREATE_ONE_ERROR";
export const RECIPIENTS_REMOVE_ONE = "RECIPIENTS_REMOVE_ONE"
export const RECIPIENTS_REMOVE_ONE_SUCCESS = "RECIPIENTS_REMOVE_ONE_SUCCESS"
export const RECIPIENTS_REMOVE_ONE_ERROR = "RECIPIENTS_REMOVE_ONE_ERROR"
export const RECIPIENTS_EDIT_ONE = "RECIPIENTS_EDIT_ONE"
export const RECIPIENTS_EDIT_ONE_SUCCESS = "RECIPIENTS_EDIT_ONE_SUCCESS"
export const RECIPIENTS_EDIT_ONE_ERROR = "RECIPIENTS_EDIT_ONE_ERROR"
export const RECIPIENTS_GET_ONE = "RECIPIENTS_GET_ONE"
export const RECIPIENTS_GET_ONE_SUCCESS = "RECIPIENTS_GET_ONE_SUCCESS"
export const RECIPIENTS_GET_ONE_ERROR = "RECIPIENTS_GET_ONE_ERROR"

/* MESSAGES */
export const MESSAGES_CLEAN_PROPS = "MESSAGES_CLEAN_PROPS";
export const MESSAGES_GET_DATA = "MESSAGES_GET_DATA";
export const MESSAGES_GET_DATA_SUCCESS = "MESSAGES_GET_DATA_SUCCESS";
export const MESSAGES_GET_DATA_ERROR = "MESSAGES_GET_DATA_ERROR";
export const MESSAGES_CREATE_ONE = "MESSAGES_CREATE_ONE";
export const MESSAGES_CREATE_ONE_SUCCESS = "MESSAGES_CREATE_ONE_SUCCESS";
export const MESSAGES_CREATE_ONE_ERROR = "MESSAGES_CREATE_ONE_ERROR";
export const MESSAGES_REMOVE_ONE = "MESSAGES_REMOVE_ONE"
export const MESSAGES_REMOVE_ONE_SUCCESS = "MESSAGES_REMOVE_ONE_SUCCESS"
export const MESSAGES_REMOVE_ONE_ERROR = "MESSAGES_REMOVE_ONE_ERROR"
export const MESSAGES_EDIT_ONE = "MESSAGES_EDIT_ONE"
export const MESSAGES_EDIT_ONE_SUCCESS = "MESSAGES_EDIT_ONE_SUCCESS"
export const MESSAGES_EDIT_ONE_ERROR = "MESSAGES_EDIT_ONE_ERROR"
export const MESSAGES_EDIT_CLEAN_PROPS = "MESSAGES_EDIT_CLEAN_PROPS"
export const MESSAGES_GET_ONE = "MESSAGES_GET_ONE"
export const MESSAGES_GET_ONE_SUCCESS = "MESSAGES_GET_ONE_SUCCESS"
export const MESSAGES_GET_ONE_ERROR = "MESSAGES_GET_ONE_ERROR"

/**VALIDATIONS */
export const VALIDATIONS_CLEAN_PROPS = "VALIDATIONS_CLEAN_PROPS"
export const VALIDATIONS_EMAILS_ACTIVATE = "VALIDATIONS_EMAILS_ACTIVATE"
export const VALIDATIONS_EMAILS_ACTIVATE_SUCCESS = "VALIDATIONS_EMAILS_ACTIVATE_SUCCESS"
export const VALIDATIONS_EMAILS_ACTIVATE_ERROR = "VALIDATIONS_EMAILS_ACTIVATE_ERROR"
export const VALIDATIONS_EMAILS_DEACTIVATE = "VALIDATIONS_EMAILS_DEACTIVATE"
export const VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS = "VALIDATIONS_EMAILS_DEACTIVATE_SUCCESS"
export const VALIDATIONS_EMAILS_DEACTIVATE_ERROR = "VALIDATIONS_EMAILS_DEACTIVATE_ERROR"
export const VALIDATIONS_FACEBOOK_ACTIVATE = "VALIDATIONS_FACEBOOK_ACTIVATE"
export const VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS = "VALIDATIONS_FACEBOOK_ACTIVATE_SUCCESS"
export const VALIDATIONS_FACEBOOK_ACTIVATE_ERROR = "VALIDATIONS_FACEBOOK_ACTIVATE_ERROR"
export const VALIDATIONS_FACEBOOK_DEACTIVATE = "VALIDATIONS_FACEBOOK_DEACTIVATE"
export const VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS = "VALIDATIONS_FACEBOOK_DEACTIVATE_SUCCESS"
export const VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR = "VALIDATIONS_FACEBOOK_DEACTIVATE_ERROR"
export const VALIDATIONS_PAYMENT_ACTIVATE = "VALIDATIONS_PAYMENT_ACTIVATE"
export const VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS = "VALIDATIONS_PAYMENT_ACTIVATE_SUCCESS"
export const VALIDATIONS_PAYMENT_ACTIVATE_ERROR = "VALIDATIONS_PAYMENT_ACTIVATE_ERROR"
export const VALIDATIONS_SPOTIFY_ACTIVATE = "VALIDATIONS_SPOTIFY_ACTIVATE"
export const VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS = "VALIDATIONS_SPOTIFY_ACTIVATE_SUCCESS"
export const VALIDATIONS_SPOTIFY_ACTIVATE_ERROR = "VALIDATIONS_SPOTIFY_ACTIVATE_ERROR"
export const VALIDATIONS_SPOTIFY_DEACTIVATE = "VALIDATIONS_SPOTIFY_DEACTIVATE"
export const VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS = "VALIDATIONS_SPOTIFY_DEACTIVATE_SUCCESS"
export const VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR = "VALIDATIONS_SPOTIFY_DEACTIVATE_ERROR"

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./recipients/actions";
export * from "./messages/actions";
export * from "./validations/actions";
