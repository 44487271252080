
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    RECIPIENTS_GET_ONE,
    RECIPIENTS_GET_DATA,
    RECIPIENTS_CREATE_ONE,
    RECIPIENTS_EDIT_ONE,
    RECIPIENTS_REMOVE_ONE,
} from '../actions';
import {
    getRecipientSuccess,
    getRecipientError,
    getRecipientsSuccess,
    getRecipientsError,
    saveRecipientSuccess,
    saveRecipientError,
    editRecipientSuccess,
    editRecipientError,
    removeRecipientSuccess,
    removeRecipientError
} from './actions';
import axios from "axios";
import {presenceApiUrl} from '../../constants/defaultValues'
import {
    retrieveRecipientByCustomerToken,
    retrieveRecipientsByCustomerToken,
    createRecipientByCustomerToken,
    editRecipientByCustomerToken,
    removeRecipientByCustomerToken
} from '../../helpers/PresenceAPI';

export function* watchGetRecipient() {
    yield takeEvery(RECIPIENTS_GET_ONE, getRecipientFn);
}

const getRecipientFnAsync = (customerId, customerToken, recipientId) =>
    retrieveRecipientByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken, recipientId)

function* getRecipientFn({ payload }) {
    const { customerId, customerToken, recipientId } = payload

    try {
        const recipients = yield call(getRecipientFnAsync, customerId, customerToken, recipientId);
        yield put(getRecipientSuccess(recipients))
    } catch (error) {
        yield put(getRecipientError(error.message));
    }
}

export function* watchGetRecipients() {
    yield takeEvery(RECIPIENTS_GET_DATA, getRecipientsFn);
}

const getRecipientsFnAsync = (customerId, customerToken) =>
    retrieveRecipientsByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken)

function* getRecipientsFn({ payload }) {
    const customerId = payload.customerId
    const customerToken = payload.customerToken

    try {
        const recipients = yield call(getRecipientsFnAsync, customerId, customerToken);
        yield put(getRecipientsSuccess(recipients))
    } catch (error) {
        yield put(getRecipientsError(error.message));
    }
}

export function* watchSaveRecipient() {
    yield takeEvery(RECIPIENTS_CREATE_ONE, saveRecipientFn);
}

const saveRecipientFnAsync = (
    customerId,
    customerToken,
    name,
    relationship,
    email,
    mobile
) => createRecipientByCustomerToken(axios)(presenceApiUrl)
    (
        customerId,
        customerToken,
        name,
        relationship,
        email,
        mobile
    )

function* saveRecipientFn({ payload }) {
    const { customerId, customerToken, values } = payload
    const { name, relationship, email, mobile } = values

    try {
        const recipient = yield call(
            saveRecipientFnAsync,
            customerId,
            customerToken,
            name,
            relationship,
            email,
            mobile
        );
        yield put(saveRecipientSuccess({name: recipient.name}))
    } catch (error) {
        yield put(saveRecipientError(error.message));
    }
}

export function* watchEditRecipient() {
    yield takeEvery(RECIPIENTS_EDIT_ONE, editRecipientFn);
}

const editRecipientFnAsync = (
    customerId,
    customerToken,
    recipientId,
    name,
    relationship,
    email,
    mobile
) => editRecipientByCustomerToken(axios)(presenceApiUrl)
    (
        customerId,
        customerToken,
        recipientId,
        name,
        relationship,
        email,
        mobile
    )

function* editRecipientFn({ payload }) {
    const { customerId, customerToken, recipientId, values } = payload
    const { name, relationship, email, mobile } = values

    try {
        const recipient = yield call(
            editRecipientFnAsync,
            customerId,
            customerToken,
            recipientId,
            name,
            relationship,
            email,
            mobile
        );
        yield put(editRecipientSuccess({name: recipient.name}))
    } catch (error) {
        yield put(editRecipientError(error.message));
    }
}

export function* watchRemoveRecipient() {
    yield takeEvery(RECIPIENTS_REMOVE_ONE, removeRecipientFn);
}

const removeRecipientFnAsync = (customerId, customerToken, recipientId) =>
    removeRecipientByCustomerToken(axios)(presenceApiUrl)(customerId, customerToken, recipientId)

function* removeRecipientFn({ payload }) {
    const { customerId, customerToken, recipientId } = payload

    try {
        const recipient = yield call(
            removeRecipientFnAsync,
            customerId,
            customerToken,
            recipientId
        );
        yield put(removeRecipientSuccess({recipientId, remove: true, name: recipient.name}))
    } catch (error) {
        yield put(removeRecipientError(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchGetRecipient),
        fork(watchGetRecipients),
        fork(watchEditRecipient),
        fork(watchSaveRecipient),
        fork(watchRemoveRecipient),
    ]);
}
