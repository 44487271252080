import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import recipients from './recipients/reducer';
import messages from './messages/reducer';
import validations from './validations/reducer';


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  recipients,
  messages,
  validations,
});

export default reducers;
