import {
  MESSAGES_CLEAN_PROPS,
  MESSAGES_GET_DATA,
  MESSAGES_GET_DATA_SUCCESS,
  MESSAGES_GET_DATA_ERROR,
  MESSAGES_GET_ONE,
  MESSAGES_GET_ONE_SUCCESS,
  MESSAGES_GET_ONE_ERROR,
  MESSAGES_CREATE_ONE,
  MESSAGES_CREATE_ONE_SUCCESS,
  MESSAGES_CREATE_ONE_ERROR,
  MESSAGES_EDIT_ONE,
  MESSAGES_EDIT_ONE_SUCCESS,
  MESSAGES_EDIT_ONE_ERROR,
  MESSAGES_EDIT_CLEAN_PROPS,
  MESSAGES_REMOVE_ONE,
  MESSAGES_REMOVE_ONE_SUCCESS,
  MESSAGES_REMOVE_ONE_ERROR,
} from '../actions';

export const cleanMessagesProps = () => ({
  type: MESSAGES_CLEAN_PROPS,
  payload: {}
})

export const cleanMesssagesEditProps = () => ({
  type: MESSAGES_EDIT_CLEAN_PROPS,
  payload: {}
})

export const getMessage = (customerId, customerToken, messageId) => ({
  type: MESSAGES_GET_ONE,
  payload: { customerId, customerToken, messageId }
});

export const getMessageSuccess = (message) => ({
  type: MESSAGES_GET_ONE_SUCCESS,
  payload: { message }
});

export const getMessageError = (message) => ({
  type: MESSAGES_GET_ONE_ERROR,
  payload:  { message }
});

export const getMessages = (customerId, customerToken) => ({
  type: MESSAGES_GET_DATA,
  payload: { customerId, customerToken }
});

export const getMessagesSuccess = (messages) => ({
  type: MESSAGES_GET_DATA_SUCCESS,
  payload: { messages }
});

export const getMessagesError = (message) => ({
  type: MESSAGES_GET_DATA_ERROR,
  payload:  { message }
});

export const saveMessage = (customerId, customerToken, values) => ({
  type: MESSAGES_CREATE_ONE,
  payload: { customerId, customerToken, values }
})

export const saveMessageSuccess = (message) => ({
  type: MESSAGES_CREATE_ONE_SUCCESS,
  payload: { message }
})

export const saveMessageError = (error) => ({
  type: MESSAGES_CREATE_ONE_ERROR,
  payload: { error }
})

export const editMessage = (customerId, customerToken, messageId, values) => ({
  type: MESSAGES_EDIT_ONE,
  payload: { customerId, customerToken, messageId, values }
})

export const editMessageSuccess = (message) => ({
  type: MESSAGES_EDIT_ONE_SUCCESS,
  payload: { message }
})

export const editMessageError = (error) => ({
  type: MESSAGES_EDIT_ONE_ERROR,
  payload: { error }
})

export const removeMessage = (customerId, customerToken, messageId) => ({
  type: MESSAGES_REMOVE_ONE,
  payload: { customerId, customerToken, messageId }
})

export const removeMessageSuccess = (message) => ({
  type: MESSAGES_REMOVE_ONE_SUCCESS,
  payload: { message }
})

export const removeMessageError = (error) => ({
  type: MESSAGES_REMOVE_ONE_ERROR,
  payload: { error }
})
