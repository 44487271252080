import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const validateHistory = location => {
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
      history.replace(path);
  }
}

// eslint-disable-next-line no-restricted-globals
const runManage = () => validateHistory(location)

export { runManage }
