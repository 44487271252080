
/**
 * Manage success response to return response data
 * @param {Object} response
 * @returns Promise
 */
const manageSuccessResponse = response => {
  if (response.data) {
    return response.data
  }
  return response;
}

/**
 * Manage error response to return error message
 * @param {Object} error
 * @returns Promise
 */
const manageErrorResponse = error => {
  if (error.response && error.response.data && error.response.data.error) {
    return Promise.reject({message: error.response.data.error});
  }
  return Promise.reject(error);
}

/**
 * Make a http post request to do login
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const login = httpClient => apiUrl => (email, password) =>
  httpClient.post(`${apiUrl}/customers/login`, {email, password})
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do login
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const loginFacebook = httpClient => apiUrl => (userFacebookId, name, email, accessTokenFacebook) =>
  httpClient.post(`${apiUrl}/customers/loginFacebook`, {userFacebookId, email, name, accessTokenFacebook})
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do login
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const register = httpClient => apiUrl => (email, password, name) =>
  httpClient.post(`${apiUrl}/customers/register`, {email, password, name})
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do register with facebook
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const registerFacebook = httpClient => apiUrl => (userFacebookId, name, email, accessTokenFacebook) =>
  httpClient.post(`${apiUrl}/customers/registerFacebook`, {userFacebookId, email, name, accessTokenFacebook})
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http get request to retrieve customer data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @returns Promise
 */
const retrieveCustomerById = httpClient => apiUrl => (customerId, customerToken) =>
  httpClient.get(
    `${apiUrl}/customers/${customerId}`,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http get request to retrieve recipient data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @returns Promise
 */
const retrieveRecipientByCustomerToken = httpClient => apiUrl => (customerId, customerToken, recipientId) =>
  httpClient.get(
    `${apiUrl}/customers/${customerId}/recipients/${recipientId}`,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http get request to retrieve recipients data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @returns Promise
 */
const retrieveRecipientsByCustomerToken = httpClient => apiUrl => (customerId, customerToken) =>
  httpClient.get(
    `${apiUrl}/customers/${customerId}/recipients/`,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do create a new recipient by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const createRecipientByCustomerToken = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  name,
  relationship,
  email,
  mobile
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/recipients/`,
    { name, relationship, contactInfo: { email, mobile } },
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do create a new recipient by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const editRecipientByCustomerToken = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  recipientId,
  name,
  relationship,
  email,
  mobile
) =>
  httpClient.put(
    `${apiUrl}/customers/${customerId}/recipients/${recipientId}`,
    { name, relationship, contactInfo: { email, mobile } },
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http delete request to do remove a recipient by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const removeRecipientByCustomerToken = httpClient => apiUrl =>
  (customerId, customerToken, recipientId) =>
    httpClient.delete(
      `${apiUrl}/customers/${customerId}/recipients/${recipientId}`,
      { headers: { Authorization: `Bearer ${customerToken}` } }
    )
      .then(manageSuccessResponse)
      .catch(manageErrorResponse)

/**
 * Make a http get request to retrieve message data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @returns Promise
 */
const retrieveMessageByCustomerToken = httpClient => apiUrl => (customerId, customerToken, messageId) =>
  httpClient.get(
    `${apiUrl}/customers/${customerId}/messages/${messageId}`,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http get request to retrieve messages data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @returns Promise
 */
const retrieveMessagesByCustomerToken = httpClient => apiUrl => (customerId, customerToken) =>
  httpClient.get(
    `${apiUrl}/customers/${customerId}/messages/`,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do create a new message by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const createMessageByCustomerToken = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  title,
  message,
  recipients
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/messages/`,
    { title, message, recipients },
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to do create a new message by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const editMessageByCustomerToken = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  messageId,
  title,
  message,
  recipients
) =>
  httpClient.put(
    `${apiUrl}/customers/${customerId}/messages/${messageId}`,
    { title, message, recipients },
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http delete request to do remove a message by customer id
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const removeMessageByCustomerToken = httpClient => apiUrl =>
  (customerId, customerToken, messageId) =>
    httpClient.delete(
      `${apiUrl}/customers/${customerId}/messages/${messageId}`,
      { headers: { Authorization: `Bearer ${customerToken}` } }
    )
      .then(manageSuccessResponse)
      .catch(manageErrorResponse)

/**
 * Make a http patch request to update customer record with emails validation data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const updateEmailsValidationByCustomer = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  emailsValidationData
) =>
  httpClient.patch(
    `${apiUrl}/customers/${customerId}`,
    { "validations.emails": emailsValidationData },
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to update customer record with facebook validation data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const activateFacebookValidationByCustomer = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  facebookValidationData
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/subscribeFacebook`,
    facebookValidationData,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to update customer record with facebook validation data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} email
 * @param {String} password
 * @returns Promise
 */
const deactivateFacebookValidationByCustomer = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  facebookValidationData
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/unsubscribeFacebook`,
    facebookValidationData,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request for validate payment
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @param {String} type
 * @param {Object} data
 * @returns Promise
 */
const activatePaymentValidationsByCustomer = httpClient => apiUrl =>
(customerId, customerToken, type, data) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/validatePayment`,
    {type, data},
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to update customer record with spotify validation data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {Object} spotifyValidationData
 * @returns Promise
 */
const activateSpotifyValidationByCustomer = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  spotifyValidationData
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/subscribeSpotify`,
    spotifyValidationData,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

/**
 * Make a http post request to update customer record with spotify validation data
 * @param {Object} httpClient
 * @param {String} apiUrl
 * @param {String} customerId
 * @param {String} customerToken
 * @param {Object} spotifyValidationData
 * @returns Promise
 */
const deactivateSpotifyValidationByCustomer = httpClient => apiUrl =>
(
  customerId,
  customerToken,
  spotifyValidationData
) =>
  httpClient.post(
    `${apiUrl}/customers/${customerId}/unsubscribeSpotify`,
    spotifyValidationData,
    { headers: { Authorization: `Bearer ${customerToken}` } }
  )
    .then(manageSuccessResponse)
    .catch(manageErrorResponse)

export {
  login,
  loginFacebook,
  register,
  registerFacebook,
  retrieveCustomerById,
  retrieveRecipientByCustomerToken,
  retrieveRecipientsByCustomerToken,
  createRecipientByCustomerToken,
  editRecipientByCustomerToken,
  removeRecipientByCustomerToken,
  retrieveMessageByCustomerToken,
  retrieveMessagesByCustomerToken,
  createMessageByCustomerToken,
  editMessageByCustomerToken,
  removeMessageByCustomerToken,
  updateEmailsValidationByCustomer,
  activateFacebookValidationByCustomer,
  deactivateFacebookValidationByCustomer,
  activatePaymentValidationsByCustomer,
  activateSpotifyValidationByCustomer,
  deactivateSpotifyValidationByCustomer,
}
